.contact {
  display: flex;
  justify-content: center;
  .contact-info {
    text-align: right;
    justify-items: right;
    padding-right: 100px;
    margin: auto 0px;
    .number {
      margin: 0;
    }
    .email {
      font-style: italic;
      margin: 0;
    }
  }

  .contact-image {
    width: 25%;
    max-width: 450px;
    min-width: 320px;
    video {
      max-height: 550px;
    }
    /* .aspect-ratio-container {
      position: relative;
      width: 100%; */
      /* padding-bottom: 150%; 2:3 aspect ratio (3 / 2 * 100%) */
      /* overflow: hidden;
    } */
    /* img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    } */
  }
  button {
    margin-top: 40px;
  }
}

@media screen and (max-width: 780px) {
    .contact {
        padding-top: 1rem;
    }
    .contact-info {
        padding-right: 2rem !important;
    }
    .contact-image {
        max-width: 300px !important;
        min-width: 250px !important;
    }
}

/* MOBILE STYLING */
.mobile {
  &.contact {
    flex-direction: column;
    align-items: center !important;
    margin: 0;
    .contact-info {
      text-align: center !important;
      font-size: 12px !important;
      padding: 0px 0px 20px 0px !important;
      margin: 0;
      width: 100%;
      button {
        margin-top: 20px;
        padding: 0;
        margin-right: 0 !important;
        padding-right: 0 !important;
        justify-content: center;
      }
    }
    .contact-image{
      background-color: pink !;
      width: 50%;
    }
  }
}

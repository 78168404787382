.dancer-profile {
  /* width: 30vw; */
  /* min-width: 225px; */

  .dancer-card {
    position: relative;
    overflow: hidden;
    width: 100%;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      box-sizing: border-box;
      pointer-events: none;
      margin: 0;
      p {
        color: white;
      }
    }
    .center-content {
      padding: 25px;
      display: flex;
      flex-direction: column;
      margin: auto;
      p {
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
      }
    }
    .hometown {
      margin-bottom: 10px;
      font-size: 12px;
    }
    
    img {
      width: 100%;
      height: auto;
      transition: opacity 0.3s ease;
      display: block;
    }
  }
  .name-container {
    text-align: center;
    padding: 10px;
    p {
      margin: 0;
      color: #ad9c00;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 3.6px;
    }
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover .overlay {
      opacity: 1;
    }
  }
}

.mobile.dancer-profile {
  .overlay {
    &.show {  
      opacity: 1;
        visibility: visible;
    }
  }
}
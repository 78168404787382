@import '../../styles/design-tokens.css';

.footer {
  padding-top: 50px;
  padding-bottom: 25px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon-container {
    margin: auto;
    .icon {
      color: var(--primary-color);
      height: 2.2rem;
      padding-bottom: 10px;
      &:hover {
        color: var(--accent-color);
        cursor: pointer;
      }
  }
  }
  .copyright {
    color: white;
    text-align: center;
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
  }
  .credit {
    margin: 0;
    padding-top: 5px;
    text-align: center;
    font-size: 14px;
    a {
      color: var(--priamry-color);
      font-weight: 600;
      &:hover {
        color: var(--accent-color);
      }
    }
  }
}

/* MOBILE STYLING */
.mobile.footer {
  padding-top: 15px !important;
  padding-bottom: 20px !important;
}
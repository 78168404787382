@import '../src/styles/design-tokens.css';

body {
  background-color: var(--background-color);
  color: var(--primary-color);
  font-family: var(--font-family);
  p {
    font-size: var(--p);
    font-weight: var(--font-weight);
  }
  button {
    width: 215px;
    height: 50px;
    font-size: 16px;
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    &:hover {
      cursor: pointer;
      border: 1px solid var(--accent-color);
      background-color: var(--accent-color);
      color: var(--primary-color);
      font-size: 16px;
    }
  }
  .header {
    position: sticky;
    top: 0;
  }
}

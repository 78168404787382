/* Colors */
:root {
  --primary-color: #fff;
  --secondary-color: #000;
  --accent-color: #ad9c00;
  --background-color: #000;
}

/* Typography */
:root {
  --font-family: 'Montserrat', sans-serif;
  --secondary-font-family: 'Italiana', sans-serif;
  --font-weight: 400;
  --p: 16px;
}

@import '../../styles/design-tokens.css';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0px 75px;
  z-index: 1;
  background-color: var(--background-color);

  .logo img {
    max-width: 375px;
  }
  .lip-icon {
    display: none;
  }

  .navbar-item {
    color: var(--primary-color);
    text-decoration: none;
    text-transform: uppercase;
    padding: 0px 12px;
    font-weight: 300;
    font-size: 14px;
    &:hover {
      color: var(--accent-color);
    }
    &.active {
      color: var(--accent-color);
    }
  }
}

@media screen and (min-width: 1010px) {
  .menu-icon {
    display: none;
  }
}
@media screen and (max-width: 950px) {
  .header {
    padding: 0px 35px;

    .logo img {
      width: 100%;
    }
  }
  .navbar {
    display: none !important;
  }
  .menu-icon {
    display: block;
    top: 0;
    right: 0;
    z-index: 3;
    font-size: 20px;
    color: var(--background-color);
    margin: 0;
    padding: 0;
    .fa-bars {
      color: var(--primary-color);
    }
  }

  .lip-icon {
    display: block !important;
    height: 50px !important;
    &:hover {
      cursor: pointer;
    }
  }

  .menu-active {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 25px;
    transition: all 0.5s ease;
    text-align: right;
    padding-top: 45px;
    padding-left: 25px;
    padding-bottom: 25px;
    padding-right: 20px;
    background-color: var(--primary-color);
    color: black;
    .navbar-item {
      color: var(--background-color);
      padding-bottom: 2px;
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    .logo {
      width: 60%;
    }
    .lip-icon {
      height: 40px !important;
      margin-bottom: 10px;
    }
  }
}

/* MOBILE STYLING */

.mobile.header {
  height: 46px;
  margin: 0;
  padding: 0px 15px;
  .logo {
    img {
      width: 180px;
    }
  }
  .menu-icon {
    margin-right: 15px;
  }
  .lip-icon {
    width: 60px !important;
  }
  .menu-active {
    top: 0px !important;
    .navbar-item {
      padding-top: 5px;
    }
  }
}

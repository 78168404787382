@import '../../styles/design-tokens.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.carousel {
  width: 100vw;
  /* height: calc(100vh - 100px); */
  max-height: 100vh;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  .logo {
    position: absolute;
    width: 40%;
    max-width: 800px;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease;
  }
}

.carousel-container {
    display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
    flex: 0 0 100%;
  }

img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  object-position: top;
}

@media screen and (max-width: 1100px) {
  .carousel {
    .logo {
      display: none;
    }
  }
}

@media screen and (max-width: 1023px) {
  .carousel {
    .arrow {
      display: none;
    }
  }
}



.nav-button {
  position: absolute;
  top: 50%;
  font-size: 58px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: none;
  color: white;
  transition: background 0.3s ease-in-out;
}

.nav-button:hover {
  background: rgba(
    0,
    0,
    0,
    0.5
  );
}
  .arrow {
    z-index: 5;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 5%;
    &:hover {
      cursor: pointer;
    }
  }
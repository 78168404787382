.dancers {
  background-color: #000;
  padding: 30px 125px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: minmax(250px, auto);
  gap: 20px;
  margin: 0 auto;
}

.mobile {
  &.dancers-page {
    h4 {
      text-align: center;
      margin: 0;
      font-weight: 400;
      padding-top: 10px;
    }
  }

  &.dancers {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    padding: 20px;
  }
}


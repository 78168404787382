.shows {
  display: flex;
  flex-direction: column;
  margin: 0px 10vw 0px 13vw;
  height: min-content;
  .poster {
    align-self: center;
    overflow: hidden !important;
    padding-top: 10px;
    .aspect-ratio-container {
      position: relative;
      width: 100%;
      overflow: hidden;
    }
    img {
      max-width: 900px;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    .location {
      display: flex;
      text-align: center;
      justify-content: center;
      .venue {
        min-width: 197px !important;
        border: 1px solid white;
        margin: 0px 50px;
        padding: 15px 25px;
        h3 {
          margin: auto;
          margin-bottom: 5px;
          border-bottom: 1px solid white;
        }
      }
      p {
        font-size: 16px;
        font-weight: 300;
        margin: 0;
        padding-bottom: 5px;
      }
    }
    h1 {
      font-weight: 400;
      font-size: 32px !important;
      letter-spacing: 1px;
      margin-top: 0;
      padding-top: 15px;
      text-align: center;
    }
    h2 {
      font-size: 25px;
      font-weight: 100;
      text-transform: uppercase;
      margin: 0;
      padding-bottom: 20px;
    }
    h3 {
      text-align: center;
      width: 100%;
      font-size: 20px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 10px !important;
      margin-top: 10px;
      padding-bottom: 5px;
    }
    h5 {
      margin: 0;
      padding-bottom: 5px;
      font-weight: 300;
    }
    .description {
      width: 85%;
      margin: auto;
      padding-top: 35px;
      p {
        font-size: 16px;
        font-weight: 300;
        margin: 0;
        padding-bottom: 20px;
      }
    }
    button {
      text-transform: uppercase;
      letter-spacing: 1.2px;
      margin-top: 20px;
      height: 35px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .shows {
    margin: 0 5rem !important;
  }
  .info {
    margin: 0px 30px;
  }
}

@media screen and (max-width: 855px) {
  .shows {
    flex-direction: column !important;
    height: auto;
    align-items: center;
    .poster {
      width: 100%;
      max-width: none;
      min-width: none;
    }
  }
  .info {
    padding-left: 1rem !important;
    .location {
      .venue {
        margin: 0px 25px !important;
      }
    }
  }
}

/* MOBILE STYLING */
.mobile {
  &.shows {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    .info {
      margin: auto !important;
      padding-bottom: 50px;
    }
    .location {
      flex-wrap: wrap;
    }
    .venue {
      margin: 5px !important;
    }
    h1 {
      padding: 15px 0px !important;
    }
    h1,
    h2 {
      font-size: 20px !important;
      margin: auto !important;
    }
    h3 {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

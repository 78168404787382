.home {
  background-color: #000;
  .header {
    position: sticky;
    top: 0;
  }
  h1 {
    font-size: 3rem;
    margin-top: 15px;
    font-weight: 100 !important;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--secondary-font-family);
  }
  .section {
    margin: 0 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .section-flex {
      max-width: 500px;
        width: 60%;
        display: flex;
        flex-direction: column;
        margin-right: 10%;
        h1 {
            font-size: 46px;
            width: 100%;
        }
        p {
            width: 100%;
        }
    }
    &.first {
      padding-bottom: 75px;
      padding-top: 25px;
    }
    &.second {
      padding-bottom: 40px;
    }
    p {
      width: 500px;
      color: white;
      font-weight: 300;
      letter-spacing: 0.25px;
    }
    .liv-bio {
      margin-left: 10%;
    }
    .section-image {
      width: 50%;
      min-width: 250px;
      max-width: 350px;
      .aspect-ratio-container {
        position: relative;
        width: 100%;
        padding-bottom: 150%;
        overflow: hidden;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
      }
    }
  }
}

@media screen and (max-width: 1005px) {
  h1 {
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 950px) {
  .section {
    p:nth-child(odd) {
      margin-right: 3% !important;
    }
    p:nth-child(even) {
      margin-left: 3% !important;
    }
  }
}

/* MOBILE STYLING */
.mobile {
  &.home {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
  }
  .section-flex {
    width: 100% !important;
    margin: auto !important;
  }
  h1 {
    font-size: 30px !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .section {
    img {
      width: 75%;
      margin: auto;
      padding: 25px 0px;
    }
    p  {
      font-weight: 200 !important;
      padding: 0px 15px;
    }
    &.first {
      padding-bottom: 15px;
    }
  }

  .section.second {
    flex-direction: column;
    width: 100%;
    margin: 0;
    p {
      margin: 0;
      width: 100%;
    }
  }
}